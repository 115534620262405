// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-js": () => import("./../../../src/pages/introduction.js" /* webpackChunkName: "component---src-pages-introduction-js" */),
  "component---src-pages-portfolio-atvaltoztato-js": () => import("./../../../src/pages/portfolio/atvaltoztato.js" /* webpackChunkName: "component---src-pages-portfolio-atvaltoztato-js" */),
  "component---src-pages-portfolio-csaladiesgyermek-js": () => import("./../../../src/pages/portfolio/csaladiesgyermek.js" /* webpackChunkName: "component---src-pages-portfolio-csaladiesgyermek-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-kismama-js": () => import("./../../../src/pages/portfolio/kismama.js" /* webpackChunkName: "component---src-pages-portfolio-kismama-js" */),
  "component---src-pages-portfolio-paros-js": () => import("./../../../src/pages/portfolio/paros.js" /* webpackChunkName: "component---src-pages-portfolio-paros-js" */),
  "component---src-pages-portfolio-portre-js": () => import("./../../../src/pages/portfolio/portre.js" /* webpackChunkName: "component---src-pages-portfolio-portre-js" */),
  "component---src-pages-portfolio-ujszulott-js": () => import("./../../../src/pages/portfolio/ujszulott.js" /* webpackChunkName: "component---src-pages-portfolio-ujszulott-js" */),
  "component---src-pages-portfolio-uzletiportre-js": () => import("./../../../src/pages/portfolio/uzletiportre.js" /* webpackChunkName: "component---src-pages-portfolio-uzletiportre-js" */)
}

