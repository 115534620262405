import "./src/assets/index.css";

import React, { useState, useEffect } from "react";

export const ScrollContext = React.createContext("scroll");

ScrollContext.displayName = "ScrollContext";

const Wrapper = ({ children }) => {
  const [state, setState] = useState(
    window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
  );

  const onScroll = () => {
    setState(
      window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);
  return (
    <ScrollContext.Provider value={state}>{children}</ScrollContext.Provider>
  );
};

export const wrapPageElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
};
